<template>
	<div class="card mb-0 h-100">
		<div class="card-body">
			<div class="h1 text-muted text-right mb-3">
				<i :class="icon" />
			</div>
			<div class="h4 mb-0">
				{{ bigLabel }}
			</div>
			<div class="medium-label mb-0 text-muted">
				{{ mediumLabel }}
			</div>
			<small
				class="text-muted text-uppercase font-weight-bold">
				{{ smallLabel }}
				<span
					v-b-tooltip.hover
					:title="tooltip"
					class="text-muted">
					<i :class="smallIcon" />
				</span>
			</small>
			<b-progress
				:value="100"
				class="progress-xs mt-4 custom-progress" />
		</div>
	</div>
</template>

<script>

export default {
	name: 'CardWidget2',
	props: {
		smallLabel: {
			type: String,
			default: '',
		},
		mediumLabel: {
			type: String,
			default: '',
		},
		bigLabel: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: '',
		},
		smallIcon: {
			type: String,
			default: '',
		},
		tooltip: {
			type: String,
			default: '',
		},
	},
	data() {
		return {};
	},
	methods: {},
};
</script>
<style>
	.medium-label {
		font-size: 12px !important;
	}
</style>

<template>
	<b-button
		v-b-tooltip.hover
		:variant="variant"
		:title="tooltip"
		:class="buttonClass"
		class="mx-1"
		style="height: 35px; width: 35px;"
		@click="$emit('click')">
		<div class="h-100 w-100 d-flex justify-content-center align-items-center">
			<i
				:class="iconClass"
				aria-hidden="true" />
		</div>
	</b-button>
</template>
<script>
export default {
	name: 'IconButton',
	components: {},
	props: {
		tooltip: {
			type: String,
			required: true,
		},
		iconClass: {
			type: String,
			required: true,
		},
		buttonClass: {
			type: String,
			default: '',
		},
		variant: {
			type: String,
			default: '',
		},
	},
};
</script>
